import { FieldStatus, HostedFieldStatus, CardTypes, FieldStatusCyberSource } from "../PaymentMethodCreditCard";
import { ProcessingBackDropProps } from '../ProcessingBackDrop';
import { PaymentProcessingDto } from '../../../../api/dto/PaymentProcessingDto';
import { SuccessReceiptDto } from '../../body/SuccessReceipt';
import { MyApp } from '../../../../context/AppContext';
import { FailedReceiptDto } from '../../body/FailedReceipt';
import { ValidityChangedEvent } from './IllionCyberSource';
import CyberSource3DSChallenge from './CyberSource3DSChallenge';
import { SharedUtils } from "../../../../utils/SharedUtils";

// +------------------------------+
//           Interfaces
// +------------------------------+
export interface FieldsState {
  fields: field[]
}

export interface field {
  containerId: string
  isValid: boolean
}
// +------------------------------+

// updateFieldStatus
// -------------------------
// Updates the visual validation of a field via its container id
function updateFieldStatus(
  fieldId: string,
  fieldValue: HostedFieldStatus,
  setFieldStatus: React.Dispatch<React.SetStateAction<FieldStatus>>
) {
  // Determine which status field to update by it's id, we spread the "prevState"
  // as we need to create a new object but want to retain the previous
  // values for the fields that won't be updated.
  if (fieldId === "cardholderName")
    setFieldStatus(prevState => ({ ...prevState, name: fieldValue }));
  else if (fieldId === "number")
    setFieldStatus(prevState => ({ ...prevState, card: fieldValue }));
  else if (fieldId === "expirationDate")
    setFieldStatus(prevState => ({ ...prevState, expiry: fieldValue }));
  else if (fieldId === "cvv")
    setFieldStatus(prevState => ({ ...prevState, cvv: fieldValue }));
}

// onValidityChangeHandler
// -------------------------
export const onValidityChangeHandler = (
  event: ValidityChangedEvent,
  setFieldStatus: React.Dispatch<React.SetStateAction<FieldStatus>>
) => {
  // Default
  let updatedFieldStatus = HostedFieldStatus.none;

  // Map the status of the hosted field to: valid/invalid/none
  if (event.valid) updatedFieldStatus = HostedFieldStatus.valid
  else if (event.couldBeValid || event.empty) updatedFieldStatus = HostedFieldStatus.none
  else updatedFieldStatus = HostedFieldStatus.invalid

  // Call the method below to update the "fieldStatus" hooked used by the UI
  updateFieldStatus(event.id, updatedFieldStatus, setFieldStatus);
}

// onCardTypeChangeHandler
// -------------------------
export const onCardTypeChangeHandler = (
  event: any,
  setCardType: React.Dispatch<React.SetStateAction<CardTypes>>,
  setFieldStatus: React.Dispatch<React.SetStateAction<FieldStatusCyberSource>>
) => {
  if (event.card.length === 1) {
    // Card type change event, get the first card...
    const card = event.card[0];
    // BrainTree lets us know the card type, so set it locally
    if (card.name === "visa") setCardType(CardTypes.visa);
    else if (card.name === "mastercard") setCardType(CardTypes.mastercard);
    else if (card.name === "amex") setCardType(CardTypes.amex);
    else {
      // Unknown card type
      setCardType(CardTypes.none);
      setFieldStatus(prevState => ({ ...prevState, card: HostedFieldStatus.invalid }));
    }
  } else {
    setCardType(CardTypes.none);
  }
}

// +--------------------------------------------------+
//               General Payment Handlers
// +--------------------------------------------------+

// Important reference var, as we need to remove an event listener from a
// previous call to the onPaymentRequestableHandler, so tracking that in global space
let currentMessageHandler: ((event: MessageEvent) => void) | null = null;

// onPaymentRequestableHandler
// -------------------------
export async function onPaymentRequestableHandler(
  method: string,
  transientToken: string,
  paymentAmount: number,
  paymentProcessingConfig: PaymentProcessingDto,
  deviceDataRef: React.MutableRefObject<string | undefined>,
  setProcessing: React.Dispatch<React.SetStateAction<ProcessingBackDropProps>>,
  setFailedReceipt: React.Dispatch<React.SetStateAction<FailedReceiptDto | undefined>>,
  setSuccessReceipt: React.Dispatch<React.SetStateAction<SuccessReceiptDto | undefined>>,
  cardHolderName: string
) {
  // Contains all the logic for taking a payment result
  // and setting the state/context needed to reflect that back to the UI
  const processPaymentResult = (result: any) => {
    // Error Messages
    const invalidDataError = "INVALID_DATA";
    const havePaidError = "You have already paid your balance";
    const genericError = "There was an error during the processing of the payment"

    if (result == null) {
      MyApp.setError(genericError)
      return
    };

    // Error codes
    const paymentInternalRefErrorCode = "IR001"
    const paymentTimeoutErrorCode = "PP001"
    const paymentProcessingErrorCode = "PP002"

    // check for specific error codes and re-wash reason if required
    if (result.reasonCode) {
      if (result.reasonCode === paymentInternalRefErrorCode ||
        result.reasonCode === paymentProcessingErrorCode ||
        result.reasonCode === paymentTimeoutErrorCode) {
        result.reason = "There was an error processing your payment, please try again later."
      }
    }

    // Check if it was a success or failed payment
    if (result.reason) {
      if (result.reason.includes(havePaidError)) {
        // If the fail reason is that of an "already paid" result,
        // show this on the error page rather then in the FailedReceipt
        MyApp.setError(result.reason);
      } else {
        // Result destined for the failed receipt page
        if (result.reason.includes(invalidDataError)) {
          result.reason = "It looks like your request timed out. Please try again later."
        }

        setFailedReceipt({
          reference: result.externalReference,
          account: result.consumerAccount ?? "",
          maxAttemptsReached: result.maxAttemptsReached ?? false,
          reason: result.reason,
          errorCode: result.reasonCode ?? "",
          maxAttemptsReason: result.maxAttemptsReason,
        });
      }
    } else {
      setSuccessReceipt({
        reference: result.externalReference,
        account: result.consumerAccount,
        amount: result.chargedAmount,
        cardRegistered: result.cardSaved
      });
    }
  }

  const handleMessage = (event: MessageEvent) => {
    // Your handler logic using the custom parameter
    if (
      event.origin === 'https://centinelapi.cardinalcommerce.com' ||
      event.origin === 'https://centinelapistag.cardinalcommerce.com'
    ) {
      // Device data captured successfully event     
      event.preventDefault();
      const data = JSON.parse(event.data);
      if (data?.MessageType === 'profile.completed' && data.Status) {
        const sessionId = data.SessionId;
        SharedUtils.debugLog('[4/5] Device data captured successfully');
        cyberSourceEnrolment(sessionId, transientToken, cardHolderName);
      }
    } else {
      event.preventDefault();
      // Other events
      if (event.data && event.data !== undefined) {
        try {
          var data: any = {};

          try {
            data = JSON.parse(event.data);
          } catch {
            // can't parse the outcome so skip
            return;
          }

          if (data?.MessageType === 'payment.result' && data.Result) {
            // We have handled a payment result and that should be the end of things, remove the message listener.
            if (currentMessageHandler) window.removeEventListener('message', currentMessageHandler);
            // We have a payment result, stop listening for any more messages...
            setProcessing({ isProcessing: false, content: "", fadeInDur: paymentProcessingConfig.messageFadeInDuration });
            var result = JSON.parse(data.Result);

            SharedUtils.debugLog('[5/5] Cybersource 3DS payment response received, payment success:', !result?.reason)
            processPaymentResult(result);
          }
        } catch (err) {
          console.error("handleMessage Error:", err);
        }
      }
    }
  };

  const cyberSourceEnrolment = async (sessionId: string, token: string, cardHolderName: string) => {
    try {
      const cybersourceEnrolmentResponse = await MyApp.threeDSecureEnrolment(sessionId, token, cardHolderName);

      if (!cybersourceEnrolmentResponse) {
        setProcessing({ isProcessing: false, content: "", fadeInDur: paymentProcessingConfig.messageFadeInDuration })
        setFailedReceipt({ account: '', reason: 'An error occurred.', errorCode: 'Unknown', maxAttemptsReached: false });
      }
      else {
        MyApp.setCardPin(cybersourceEnrolmentResponse.completePaymentResponse.cardPin)
      }

      if (cybersourceEnrolmentResponse?.result === 'CHALLENGE') {

        SharedUtils.debugLog('[4.5/5] Cybersource payment was challenged, 3DS verification required');

        let challengeWidth = "300px";
        let challengeHeight = "600px";

        if (cybersourceEnrolmentResponse.challengeWindowWidth && cybersourceEnrolmentResponse.challengeWindowHeight) {
          challengeWidth = cybersourceEnrolmentResponse.challengeWindowWidth + "px";
          challengeHeight = cybersourceEnrolmentResponse.challengeWindowHeight + "px";
        }

        setProcessing({
          isProcessing: true,
          content:
            <CyberSource3DSChallenge
              formActionUrl={cybersourceEnrolmentResponse.stepUpUrl}
              mdValue={`{"WebpayToken": "${MyApp.token}", "TransientTokenJwt": "${transientToken}", "InternalRef":"${MyApp.getInternalRef()}", "CardHolderName":"${cardHolderName}"}`}
              jwtAccessToken={cybersourceEnrolmentResponse.accessToken}
              challengeFrameWidth={challengeWidth}
              challengeFrameHeight={challengeHeight}
            />,
          fadeInDur: paymentProcessingConfig.messageFadeInDuration,

        })
      } else {
        // No 3DS challenge, so process the payment result that came back from threeDSecureEnrolment()
        // Close off the payment processing backdrop...
        setProcessing({ isProcessing: false, content: "", fadeInDur: paymentProcessingConfig.messageFadeInDuration })
        const result = cybersourceEnrolmentResponse?.completePaymentResponse;
        SharedUtils.debugLog('[5/5] Cybersource payment response received, payment success:', !result?.reason)
        processPaymentResult(result);
      }
    } catch {
      setProcessing({ isProcessing: false, content: "", fadeInDur: paymentProcessingConfig.messageFadeInDuration })

      setFailedReceipt({
        account: '',
        reason: 'An error occurred.',
        errorCode: 'Unknown',
        maxAttemptsReached: false,
      });

    }
  }

  const refreshMessageHandler = () => {
    // 'currentMessageHandler' (global var) is a reference to any previously added local message handler
    // via 'window.addEventListener()', this is needed so the 'window.removeEventListener()'
    // will work properly, as it removes by reference, so we need to track the current local
    // message handler that was used.
    if (currentMessageHandler) {
      // If currentMessageHandler is set, remove it, safety catch, should
      // have been removed during the 'handleMessage' function but incase that had errors
      // we will remove here if we find that it is set
      window.removeEventListener('message', currentMessageHandler);
    }

    currentMessageHandler = handleMessage;
    window.addEventListener('message', currentMessageHandler);
  }

  async function cybersourceCardPayment() {
    try {
      const threeDSecureResponse = await MyApp.setup3DSPayment(transientToken);

      if (!threeDSecureResponse?.errorMessage) {
        refreshMessageHandler();

        const content = JSON.parse(threeDSecureResponse?.contents ?? '');
        const access = content.AccessToken;
        const deviceUrl = threeDSecureResponse?.returnUrl ?? '';

        MyApp.setInternalRef(threeDSecureResponse?.internalRef ?? '');

        const deviceForm = document.getElementById('cybersource-device-form') as HTMLFormElement;
        deviceForm.action = deviceUrl;

        const deviceJwt = document.getElementById('cybersource-device-jwt') as HTMLInputElement;
        deviceJwt.value = access;

        deviceForm.submit();
        SharedUtils.debugLog('[3/5] Submitted device-data capture form');
      } else {
        console.error('threeDSecureResponse error:', threeDSecureResponse?.errorMessage);
        MyApp.setError(threeDSecureResponse?.errorMessage ?? 'We were unable to verify your details. Please try again later.');
      }
    } catch (error) {
      console.error('An error occurred while retrieving 3DSResponse:', error);
      MyApp.setError('We were unable to process your payment. Please try again later.');
    }
  }

  // Check the payment method and process the as needed
  const isCardPayment = method.toLowerCase() === 'card';
  const isApplePayment = method.toLowerCase() === 'applepay';
  const isGooglePayment = method.toLowerCase() === 'googlepay';

  if (isCardPayment) {
    // 3DS Credit card payment
    // ---------------------------
    await cybersourceCardPayment();

  } else {
    var paymentResponse = null;

    // Google pay payment
    // -------------------------
    if (isGooglePayment) {
      try {
        paymentResponse = await MyApp.CybersourceDigitalWalletPayment(transientToken, "GooglePay");
        MyApp.setCardPin("GOOG");
      } catch (e: any) {
        MyApp.setError(e?.message);
      }
    }

    // Apple pay payment
    // -------------------------
    if (isApplePayment) {
      MyApp.setCardPin("APPL");
      // Stub for future changes when in-line Apple pay processing will be moved here.
    }

    processPaymentResult(paymentResponse);
  }
}