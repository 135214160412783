import { ThreeDSecureEnrolmentResponse, ThreeDSecureSetupResponse } from "../api/dto/ThreeDSecureDto";
import { CardOptOutResponseDto } from "../api/dto/CardOptOutResponseDto";
import { CompletePaymentResponseDto } from "../api/dto/CompletePaymentResponseDto";
import { CompleteDigitalWalletPaymentResponseDto } from "../api/dto/CompleteDigitalWalletPaymentResponseDto";
import { ApplePayGetPaymentSessionResponseDto } from "../api/dto/ApplePayGetPaymentSessionResponseDto";
import { PaymentDto, PaymentResponse } from "../api/dto/PaymentDto";
import { VerifyPasscodeDto, VerifyResponseDto } from "../api/dto/VerifyRp";
import { CompletePaymentRQ } from "../api/rq/CompletePaymentRQ";
import { AppState } from "./AppState";
import { PayPalCreateOrderResponseDto } from "../api/dto/PayPalCreateOrderResponseDto";

/**
 * App state update action
 */
export enum AppStateAction {
  /**
   * Set error message
   */
  ERROR,

  /**
   * Payment data
   */
  PAYMENTDATA,

  /**
   * Static resource is loaded
   */
  RESOURCE,

  /**
   * Application is ready
   */
  READY,
}

/**
 * App state dispatch
 */
export interface AppStateDispatch {
  action: AppStateAction;
  data?: Partial<AppState> | string | undefined;
}

/**
 * App context interface
 */
export interface IAppContext {
  /**
   * App Context
   */
  context: React.Context<AppState>;

  /**
   * App state
   */
  state: AppState;

  /**
   * App state dispatch
   */
  dispatch?: React.Dispatch<AppStateDispatch>;

  /**
   * App context provider
   */
  provider: React.FunctionComponent<React.PropsWithChildren>;

  /**
   * localhost for http://localhost:4200/landing?query=1#2
   */
  readonly hostname: string;

  /**
   * Local domain path like '/domains/localhost'
   */
  readonly domainPath: string;

  /**
   * Payment token
   */
  readonly token: string;

  /**
   * Load static resource of the domain
   * @returns Result
   */
  loadStaticResource(): Promise<AppState | undefined>;

  /**
   * Query payment token
   */
  queryToken(verifyRpToken?: string): Promise<PaymentResponse | undefined>;

  /**
   * Get card token from apple
   */
  applePayGetPaymentSession(validationURL: string): Promise<ApplePayGetPaymentSessionResponseDto | undefined>;

  /**
  * Google/Apple Payment Request
  */
  CybersourceDigitalWalletPayment(cardTokenJWT: string, paymentChannel: string): Promise<CompleteDigitalWalletPaymentResponseDto | undefined>;

  /**
   * Complete payment
   * @param rq Request data
   */
  completePayment(rq: CompletePaymentRQ): Promise<CompletePaymentResponseDto | undefined>;

  /**
   * Sends the passcode for each VRP challenge to the server for verification.
   * @param passcodes The passcodes to the challenge(s).
   * @returns the verification response.
   */
  verify(passcodes: VerifyPasscodeDto[]): Promise<VerifyResponseDto | undefined>;

  /**
   * Set Link href
   * @param rel Link rel
   * @param href href property
   */
  setLinkHref(rel: string, href: string): void;

  /**
     * Get server side context for Cybersource
     */
  createServerSideContext(): Promise<string | undefined>;

  /**
   * Setup 3DS Payment for Cybersource
   */
  setup3DSPayment(transientToken: string): Promise<ThreeDSecureSetupResponse | undefined>;

  /**
 * Setup 3DS Enrolment for Cybersource
 */
  threeDSecureEnrolment(referenceId: string, transientTokenJwt: string, cardHolderName: string): Promise<ThreeDSecureEnrolmentResponse | undefined>;

  /* 
  * Create PayPal Order
  */
  paypalCreateOrder(registerToken: boolean): Promise<PayPalCreateOrderResponseDto | undefined>;

  /**
   * Set application is ready
   */
  setReady(): void;

  /**
   * Set error message
   * @param message Error message
   */
  setError(message?: string): void;

  /**
   * Update icons for different scenarios
   */
  updateIcons(): void;

  /**
   * Update resource
   * @param res Resource
   */
  updateResource(res: AppState): void;

  /**
   * Update payment
   * @param payment Payment data
   */
  updatePaymentData(payment: PaymentDto): void;

  /**
   * Unregister Card
   */
  cardOptOut(): Promise<CardOptOutResponseDto | undefined>;

  /**
   * Sets the last 4 digits, used for un-registering card
   * @param cardPin Last 4 digits of card
   */
  setCardPin(cardPin: string): void;

  setInternalRef(internalRef: string): void;

  getInternalRef(): string;
}
