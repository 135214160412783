import React, { ReactNode } from 'react';
import { MyApp } from '../../../../context/AppContext';
import { jwtDecode } from 'jwt-decode';
import { SharedUtils } from '../../../../utils/SharedUtils';

// Types and Interfaces
// --------------------------------------------------
export type FieldMapping = {
  ContainerId: string;
  MicroformFieldName: string;
};

export type PaymentAmount = {
  currency: string;
  total: number;
  fractionDigits?: number;
};

export type PaymentMethods = {
  card?: React.RefCallback<HTMLElement>;
  applePay?: React.RefCallback<HTMLElement>;
  googlePay?: React.RefCallback<HTMLElement>;
};

export type ApplePayOptions = {
  totalLabel?: string;
  displayName?: string;
};

export type CardOptions = {
  billingAddress?: any;
};

export type EnvironmentType = "PRODUCTION" | "TEST";

export interface ValidityChangedEvent {
  id: string
  valid: boolean
  couldBeValid: boolean
  empty: boolean
}

// Note that at the time of creating this interface
// only GooglePayEnabled is being used, the other two
// settings are there for future changes (stubs), just
// not part of current updates that require GooglePay
// scripts to not be loaded at runtime if disabled
export interface EnabledPaymentMethods {
  CreditCardEnabled: boolean,
  ApplePayEnabled: boolean,
  GooglePayEnabled: boolean,
}

type PaymentMethod = keyof PaymentMethods | "paypal-paylater";

type PaymentErrorHandler = (
  method: PaymentMethod,
  reason: string
) => void;

// Internally tracking field statuses
// --------------------------------------------------
enum CybersourceFieldStatus {
  valid, invalid, none
}

// Cybersource related
type CybersourceFieldStatuses = {
  name: CybersourceFieldStatus,
  card: CybersourceFieldStatus,
  expiry: CybersourceFieldStatus,
  expMonth: CybersourceFieldStatus,
  expYear: CybersourceFieldStatus,
  cvv: CybersourceFieldStatus
}

const defaultCybersourceFieldStatuses = {
  name: CybersourceFieldStatus.none,
  card: CybersourceFieldStatus.none,
  expiry: CybersourceFieldStatus.none,
  expMonth: CybersourceFieldStatus.none,
  expYear: CybersourceFieldStatus.none,
  cvv: CybersourceFieldStatus.none
}

// ---------------------------------------------------------------------------
//                            Google Pay Related
// ---------------------------------------------------------------------------

// Load the required google pay scripts
// --------------------------------------------------
function loadGooglePayScript() {
  if (typeof google != "undefined" && google?.payments?.api?.PaymentsClient)
    return Promise.resolve();

  return new Promise<void>((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.async = true;
    script.onerror = (err) => {
      console.log("script.onerror", err);
      reject(err);
    };
    script.onload = () => {
      resolve();
    };
    document.head.appendChild(script);
  });
}

// Google Pay options setting in the webpay site data 
// --------------------------------------------------
export type GooglePayOptions = {
  buttonOptions?: Omit<google.payments.api.ButtonOptions, "onClick">;
  merchantId?: string;
  totalPriceStatus?: string;
  version?: number;
  allowedCardNetworks: google.payments.api.CardNetwork[]
};

// Main Google Pay create
// --------------------------------------------------
async function createGooglePay(
  options: GooglePayOptions,
  environment: EnvironmentType,
  amount: PaymentAmount,
  onPaymentRequestable: (method: string, transientToken: string, cardHolderName: string) => Promise<void>,
  onPaymentStart?: (event: MouseEvent, element: HTMLElement) => boolean | void,
  onPaymentEnd?: () => void
): Promise<React.RefCallback<HTMLElement> | undefined> {

  // Load google payment script
  await loadGooglePayScript();

  // Destructuring properties from the options
  const {
    buttonOptions,
    merchantId,
    totalPriceStatus = "FINAL",
    version = 2,
    allowedCardNetworks
  } = options;

  // Validate the allowedCardNetworks settings array
  const cardNetworksAll = ["AMEX", "DISCOVER", "ELECTRON", "ELO", "ELO_DEBIT", "INTERAC", "JCB", "MAESTRO", "MASTERCARD", "VISA"];
  const cardNetworksValid = allowedCardNetworks.every(network => cardNetworksAll.includes(network));
  if (!cardNetworksValid) console.log("Google pay allowed card networks contains an invalid option.");

  // Validate the environment setting
  const environmentTypes = ["PRODUCTION", "TEST"];
  const environmentValid = environmentTypes.includes(environment);
  if (!environmentValid) console.log("Google pay environment contains an invalid option.");

  // Google payment client
  const paymentClient = new google.payments.api.PaymentsClient({ environment });
  const allowedCardAuthMethods: google.payments.api.CardAuthMethod[] = ["PAN_ONLY", "CRYPTOGRAM_3DS"];

  // Tokenization specification using Cybersource merchant Id
  const tokenizationSpecification: google.payments.api.PaymentGatewayTokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'cybersource',
      gatewayMerchantId: merchantId ?? ''
    }
  };

  const basePaymentMethodSpec: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks
    }
  };

  // Full card payment method with tokenization specification
  const paymentMethodSpec: google.payments.api.PaymentMethodSpecification = {
    ...basePaymentMethodSpec,
    tokenizationSpecification: tokenizationSpecification
  };

  const request = {
    apiVersion: version,
    apiVersionMinor: 0,
    allowedPaymentMethods: [basePaymentMethodSpec]
  };

  // Google payment isReadyToPay response
  // Safari failed to catch errors with await paymentClient.isReadyToPay
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const isReadyToPay = paymentClient.isReadyToPay({
    apiVersion: request.apiVersion,
    apiVersionMinor: request.apiVersionMinor,
    allowedPaymentMethods: request.allowedPaymentMethods,
    existingPaymentMethodRequired: true
  });

  if (isSafari || (await isReadyToPay).result) {
    return (button) => {
      if (button == null) return;

      const onClick = async (event: Event) => {
        if (onPaymentStart && event instanceof MouseEvent && onPaymentStart(event, button) === false) return;

        try {
          const paymentDataRequest: google.payments.api.PaymentDataRequest = {
            apiVersion: request.apiVersion,
            apiVersionMinor: request.apiVersionMinor,
            allowedPaymentMethods: [paymentMethodSpec],
            transactionInfo: {
              totalPriceStatus: totalPriceStatus as google.payments.api.TotalPriceStatus,
              totalPrice: amount.total.toFixed(amount.fractionDigits ?? 2),
              currencyCode: amount.currency
            },
            merchantInfo: {
              merchantId: merchantId ?? ''
            }
          };

          // Request payment data, this makes the Google pay modal show to the user
          // and returns the selected card or a cancel result
          const paymentData = await paymentClient.loadPaymentData(paymentDataRequest);

          // https://developer.cybersource.com/content/dam/docs/cybs/en-us/google-pay/developer/barclays/rest/googlepay.pdf
          // Cybersource requires us to base64 encode the encrypted card (token property)
          var encryptedCardToken = paymentData.paymentMethodData.tokenizationData.token;
          var cardTokenBase64 = btoa(encryptedCardToken);

          onPaymentRequestable("googlepay", cardTokenBase64, '');
        } catch (ex) {
          SharedUtils.debugLog("ex:", ex);
          if (onPaymentEnd) onPaymentEnd();
        }
      };

      if (button instanceof HTMLButtonElement) {
        button.addEventListener("click", onClick);
      } else {
        button.replaceChildren(paymentClient.createButton({ onClick, ...buttonOptions }));
      }
    };
  }
}

// ---------------------------------------------------------------------------
//                       IllionCyberSource Props
// ---------------------------------------------------------------------------

export type IllionCyberSourceProps = {
  children: (methods: PaymentMethods, amount: PaymentAmount) => ReactNode;
  onCardTypeChange?: (event: any) => void;
  onValidityChangeHandler?: (event: any) => void;
  onLoading?: () => React.ReactNode;
  onPaymentStart?: () => void;
  onPaymentEnd?: () => void;
  onPaymentRequestable: (method: string, payload: string, cardHolderName: string) => Promise<void>;
  onError?: PaymentErrorHandler;
  onPaymentError: PaymentErrorHandler;
  enabledPaymentMethods: EnabledPaymentMethods;
  environment: EnvironmentType;
  applePay?: ApplePayOptions;
  googlePay?: GooglePayOptions;
  card?: CardOptions;
  amount: PaymentAmount;
}

// ---------------------------------------------------------------------------
//                       IllionCyberSource Component
// ---------------------------------------------------------------------------

export default function IllionCyberSource(props: IllionCyberSourceProps) {
  const {
    children,
    onCardTypeChange,
    onValidityChangeHandler,
    onLoading = () => "...",
    onPaymentStart,
    onPaymentEnd,
    onPaymentRequestable,
    onPaymentError,
    onError = (method, reason) => console.log(`${method}: ${reason}`),
    enabledPaymentMethods,
    environment,
    googlePay,
    amount
  } = props;

  // States
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const [captureContext, setCaptureContext] = React.useState("");
  const [clientLibraryUrl, setClientLibraryUrl] = React.useState("");
  const [methods, setMethods] = React.useState<PaymentMethods>();

  // Refs
  const fieldStatus = React.useRef<CybersourceFieldStatuses>(defaultCybersourceFieldStatuses);
  const cardHolderName = React.useRef<string>('');
  const expiryMonth = React.useRef<string>('');
  const expiryYear = React.useRef<string>('');
  const mountedAt = React.useRef<number>(0);

  // Styles - TODO: put these in a config location resource/site
  const cybersourceInputFieldStyles = {
    input: {
      'font-size': '16px',
      'font-family': 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
    },
    ':disabled': { cursor: 'not-allowed' }
  };

  // updateFieldStatus
  // -------------------------
  function updateFieldStatus(
    fieldId: string,
    fieldValue: CybersourceFieldStatus
  ) {
    if (fieldId === "cardholderName") {
      fieldStatus.current = { ...fieldStatus.current, name: fieldValue };
      if (onValidityChangeHandler) { onValidityChangeHandler(createValidityChangedEvent("cardholderName", fieldStatus.current.name)); }
    }
    else if (fieldId === "number") {
      fieldStatus.current = { ...fieldStatus.current, card: fieldValue };
      if (onValidityChangeHandler) { onValidityChangeHandler(createValidityChangedEvent("number", fieldStatus.current.card)); }
    }
    else if (fieldId === "expirationDate") {
      fieldStatus.current = { ...fieldStatus.current, expiry: fieldValue };
      if (onValidityChangeHandler) { onValidityChangeHandler(createValidityChangedEvent("expirationDate", fieldStatus.current.expiry)); }
    }
    else if (fieldId === "cvv") {
      fieldStatus.current = { ...fieldStatus.current, cvv: fieldValue }
      if (onValidityChangeHandler) { onValidityChangeHandler(createValidityChangedEvent("cvv", fieldStatus.current.cvv)); }
    };
  }

  // onFieldChangeHandler
  // -------------------------
  function onFieldChangeHandler(
    event: ValidityChangedEvent,
  ) {
    // Default
    let status = CybersourceFieldStatus.none;

    // Map the status of the hosted field to: valid/invalid/none
    if (event.valid) status = CybersourceFieldStatus.valid
    else if (event.empty || event.couldBeValid) status = CybersourceFieldStatus.none
    else status = CybersourceFieldStatus.invalid

    updateFieldStatus(event.id, status);
  }

  // createValidityChangedEvent
  // -------------------------
  // Helps create a ValidityChangedEvent based on the current fields status
  //
  function createValidityChangedEvent(id: string, status: CybersourceFieldStatus) {
    let event = {
      id: id,
      valid: status === CybersourceFieldStatus.valid,
      empty: status === CybersourceFieldStatus.none,
      couldBeValid: false
    } as ValidityChangedEvent
    return event;
  }

  // isNumeric
  // -------------------------
  // You could use ParseInt and check that the result is !NaN, but that strips
  // out letters in the raw value rather then returning a failed to Int result
  //
  function isNumeric(value: string) {
    return /^-?\d+$/.test(value);
  }

  // getExpiryStatus
  // -------------------------
  // Takes the expiry month/year values and determines the validation style to 
  // apply to the field container in question
  //
  function getExpiryStatus(month: string, year: string): CybersourceFieldStatus {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // JavaScript months are 0-11
    const currentYear = currentDate.getFullYear();

    // Month/Year fields are empty, bail out early
    if (month.length === 0 && year.length === 0) return CybersourceFieldStatus.none;

    // Convert month and year to integers
    let monthNumber = parseInt(month);
    let yearNumber = parseInt(year);

    // Validate each part of the expiry date
    let monthValid = (isNumeric(month) && monthNumber >= 1 && monthNumber <= 12);
    // Year must be current or future, but the Max term for an expiry year is 4-5 years from being issued. Since at
    // card entry we have no idea when said card was issued we are going to assume current year + 5 years is the
    // limit of how far in the future the expiry year can be.
    let yearValid = (isNumeric(year) && yearNumber >= currentYear && yearNumber <= (currentYear + 5));

    // If one expiry part (Month/Year) is valid and the other empty, don't style
    // the expiry container as invalid yet, the user still has the other part to
    // fill out
    if (month.length === 0 && yearValid) return CybersourceFieldStatus.none;
    if (year.length === 0 && monthValid) return CybersourceFieldStatus.none;

    if (!monthValid) {
      return CybersourceFieldStatus.invalid;
    } else if (!yearValid) {
      if (year.length !== 4 && isNumeric(year)) return CybersourceFieldStatus.none;
      return CybersourceFieldStatus.invalid;
    } else if (yearNumber === currentYear && monthNumber < currentMonth) {
      return CybersourceFieldStatus.invalid;
    }
    // Assume valid as all invalid/none scenarios should have been checked for...
    return CybersourceFieldStatus.valid;
  }

  React.useEffect(() => {
    // Step 1
    const fetchCaptureContext = async () => {
      if (!captureContext || !clientLibraryUrl) {
        try {
          const token = await MyApp.createServerSideContext();
          if (token) {
            const decodedToken = jwtDecode(token) as any;
            const clientLibraryUrl = decodedToken.ctx[0].data.clientLibrary;
            const captureToken = token.replace(/^"|"$/g, '');

            setCaptureContext(captureToken);
            setClientLibraryUrl(clientLibraryUrl);

            SharedUtils.debugLog('[1/5] Received server side context');
          } else {
            throw new Error('Failed to retrieve JWT token.');
          }
        } catch (error: any) {
          console.error('An error occurred while retrieving JWT token:', error);
          if (onPaymentError) (onPaymentError("card", "An error occurred setting up your payment. Please try again later."));
        }
      }
    };

    // Step 3
    const loadScript = (url: string) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.id = 'flex-microform';
        script.src = url;
        script.onload = () => resolve(script);
        script.onerror = () => reject(new Error(`Script load error for ${url}`));
        document.head.append(script);
      });
    };

    // Step 2
    const loadAndInitializeMicroform = async (clientLibraryUrl: string, items: PaymentMethods) => {
      try {
        if (clientLibraryUrl) {
          await loadScript(clientLibraryUrl);
          setScriptLoaded(true);
        }

        const flex = new (window as any).Flex(captureContext);
        const microform = flex.microform({ styles: cybersourceInputFieldStyles });

        // Set a reference to the credit card
        const cardRef = await createCard(microform);
        items.card = cardRef
      } catch (error) {
        SharedUtils.debugLog('An error occurred while loading and initializing Microform:', error);
      }
    };

    const setupPaymentMethods = async (items: PaymentMethods) => {
      // Credit cards, setup the host fields regarding
      if (!document.querySelector('#flex-microform')) {
        fetchCaptureContext();

        if (clientLibraryUrl) {
          SharedUtils.debugLog('[2/5] Adding flex microform to dom');
          loadAndInitializeMicroform(clientLibraryUrl, items);
        }
      }

      if (googlePay && enabledPaymentMethods.GooglePayEnabled) {
        // options are set, init Google pay
        try {
          const googlePayRef = await createGooglePay(
            googlePay,
            environment,
            amount,
            onPaymentRequestable,
            onPaymentStart,
            onPaymentEnd
          );

          if (googlePayRef == null) {
            onError("googlePay", "isReadyToPay failed");
          } else {
            items.googlePay = googlePayRef;
          }
        } catch (error) {
          onError("googlePay", `setup error:${error}`)
        }
      }

      // Set the available payment methods
      setMethods(items);
    }

    // For debug <React.StrictMode> purpose
    const milliseconds = Date.now();
    if (mountedAt.current && milliseconds - mountedAt.current < 100) return;
    mountedAt.current = milliseconds;

    const items: PaymentMethods = {};
    // Starting point, just does Card and Google pay at the moment,
    // Apple pay and Pay pal should be moved here in the future.

    setupPaymentMethods(items);

    return (() => {
      const script = document.getElementById('flex-microform');
      if (script) {
        script.parentNode?.removeChild(script);
        setClientLibraryUrl("");
        setCaptureContext("");
      }
    });
  }, [clientLibraryUrl, captureContext])

  async function createCard(
    microform: any,
  ): Promise<React.RefCallback<HTMLElement>> {
    return (container) => {
      if (container == null) return;

      // Get a reference on the pay now button
      const submit = container.querySelector<HTMLElement>(
        '[type="submit"], #submit'
      );
      if (submit == null) return;

      // Cybersource doesn't have a microform field for:
      // Name on card or Expiry date, we have to handle those ourselves
      // hence the blank MicroformFieldName on those...
      const fieldMappings: FieldMapping[] = [
        { ContainerId: "cardholderName", MicroformFieldName: "" },
        { ContainerId: "number", MicroformFieldName: "number" },
        { ContainerId: "expirationDate", MicroformFieldName: "" },
        { ContainerId: "cvv", MicroformFieldName: "securityCode" },
      ];

      // Iterate the file
      fieldMappings.forEach((fieldMapping) => {
        const selector = `#${fieldMapping.ContainerId}`;
        const fieldContainer = container.querySelector<HTMLElement>(selector);
        if (fieldContainer) {
          // Pass properties with container's data-*
          const ds = fieldContainer.dataset;

          // Name On Card Change Setup
          if (fieldMapping.ContainerId === "cardholderName") {
            const containerId = "cardholderName";
            const fieldId = "name"

            // Inject custom input field for name on card and get a handle on it
            fieldContainer.innerHTML = '<input type="text" class="custom-field" id="' + fieldId + '" name="name" placeholder="Name on Card" maxLength="26"/>';
            let cardName = document.getElementById(fieldId) as HTMLInputElement;

            cardName?.addEventListener('input', (event) => {
              const target = event.target as HTMLInputElement;
              let cardNameValue = target.value;
              cardHolderName.current = cardNameValue;

              // Validation part
              let updatedStatus = CybersourceFieldStatus.none;

              // IMPORTANT: This regex is also in the MPM API, any changes to it here
              //            must be reflected into the API also to avoid indifferent validation
              //
              //            Test the name contains valid characters, the positive look ahead in the regex is
              //            important to make sure at least 1 letter is somewhere in the name.
              const nameRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9 .,'-]+$/;

              // Trim out any white spacing around the name before validating
              const nameValid = nameRegex.test(cardHolderName.current.trimStart().trimEnd())
              if (nameValid) {
                updatedStatus = CybersourceFieldStatus.valid;
              } else {
                // Only show invalid field CSS if the field is not empty
                if (cardHolderName.current.length > 0) {
                  updatedStatus = CybersourceFieldStatus.invalid;
                }
              }

              if (nameRegex.test(cardHolderName.current)) updatedStatus = CybersourceFieldStatus.valid;

              let changeEvent = createValidityChangedEvent(containerId, updatedStatus)
              onFieldChangeHandler(changeEvent);
            });
          }

          // Card number setup
          //--------------------------------------------
          if (fieldMapping.ContainerId === "number") {
            const microField = microform.createField(fieldMapping.MicroformFieldName, { placeholder: ds.placeholder });
            microField.on('change', (event: any) => {
              onFieldChangeHandler({ id: "number", ...event });
              //if (onValidityChangeHandler) onValidityChangeHandler({ id: "number", ...event });
              if (onCardTypeChange) onCardTypeChange(event);
            });
            microField.load(fieldContainer);
          }
          //--------------------------------------------

          // Expiry date setup
          if (fieldMapping.ContainerId === "expirationDate") {
            // Custom expiry date stuff
            // TODO: This needs to be update to use the React way of injecting components
            // Hopefully the inputmode="numeric" will cause the keypad to show on mobile, changing this
            // to a type of number shows those ugly up/down buttons to the right of the input
            fieldContainer.innerHTML = '<input class="custom-field" type="text" inputmode="numeric" autocomplete="off" id="expMonth" name="expMonth" placeholder="MM" maxLength="2" />' +
              '<span class="custom-field custom-expiry-slash">/</span>' +
              '<input class="custom-field" type="text" inputmode="numeric" autocomplete="off" id="expYear" name="expYear" placeholder="YYYY" maxLength="4" />';

            let expMonth = document.getElementById('expMonth') as HTMLInputElement;
            let expYear = document.getElementById('expYear') as HTMLInputElement;

            // Expiry Month Change Setup
            expMonth?.addEventListener('input', (event) => {
              const target = event.target as HTMLInputElement;
              let expMonthValue = target.value;
              expiryMonth.current = expMonthValue;

              if (expMonth.value.length === 2) {
                expYear?.focus();
              }

              let containerId = "expirationDate";
              let updatedStatus = getExpiryStatus(expiryMonth.current, expiryYear.current);
              let changeEvent = createValidityChangedEvent(containerId, updatedStatus)
              onFieldChangeHandler(changeEvent);
            });

            expMonth?.addEventListener('keydown', function (event) {
              if (event.key === 'ArrowRight' && expYear.selectionStart === 0) {
                event.preventDefault();
                expYear.focus();
                expYear.selectionStart = expYear.selectionEnd = 0;
              }
            });

            // Expiry Year Change Setup        
            expYear?.addEventListener('input', (event) => {
              const target = event.target as HTMLInputElement;
              // Update the expiryYear ref value
              let expYearValue = target.value;
              expiryYear.current = expYearValue;

              // Process the current expiry values and set
              let containerId = "expirationDate";
              let updatedStatus = getExpiryStatus(expiryMonth.current, expiryYear.current);
              let changeEvent = createValidityChangedEvent(containerId, updatedStatus)
              onFieldChangeHandler(changeEvent);
            });

            expYear?.addEventListener('keydown', function (event) {
              if (event.key === 'Backspace' && expYear.selectionStart === 0) {
                // Hitting the backspace at the start of the expiry year input will 
                // move to the month input and delete the last digit on the month 
                event.preventDefault();
                expMonth.focus();
                expMonth.value = expMonth.value.slice(0, -1);
              } else if (event.key === 'ArrowLeft' && expYear.selectionStart === 0) {
                event.preventDefault();
                expMonth.focus();
                expMonth.selectionStart = expMonth.selectionEnd = expMonth.value.length;
              }
            });
          }

          // CVV number setup
          if (fieldMapping.ContainerId === "cvv") {
            const microField = microform.createField(fieldMapping.MicroformFieldName, { placeholder: ds.placeholder });
            microField.on('change', (event: any) => {
              onFieldChangeHandler({ id: "cvv", ...event })
              //if (onValidityChangeHandler) onValidityChangeHandler({ id: "cvv", ...event });
            });
            microField.load(fieldContainer);
          }
        }
      });

      submit.addEventListener('click', (event) => {
        const isValid = (status: CybersourceFieldStatus) => {
          if (status === CybersourceFieldStatus.valid) return true;
          return false;
        }

        // Check if the field statuses are in a valid state
        let nameValid = isValid(fieldStatus.current.name);
        let cardValid = isValid(fieldStatus.current.card);
        let expiryValid = isValid(fieldStatus.current.expiry)
        let cvvValid = isValid(fieldStatus.current.cvv)

        // Anything not valid, update the field status to reflect, the field status is used to 
        // show the valid/invalid CSS and validation message on the associated field 
        if (!nameValid) updateFieldStatus("cardholderName", CybersourceFieldStatus.invalid);
        if (!cardValid) updateFieldStatus("number", CybersourceFieldStatus.invalid);
        if (!expiryValid) updateFieldStatus("expirationDate", CybersourceFieldStatus.invalid);
        if (!cvvValid) updateFieldStatus("cvv", CybersourceFieldStatus.invalid);

        // All field must be valid, exit out if something isn't
        if (!nameValid || !cardValid || !expiryValid || !cvvValid) return;
        if (onPaymentStart) onPaymentStart();

        // Pad the expiry month with a zero if it is a single digit in length
        let expMonth = (document.querySelector('#expMonth') as HTMLInputElement).value;
        expMonth = expMonth.length === 1 ? expMonth.padStart(2, '0') : expMonth;

        const createTokenOptions = {
          name: (document.querySelector('#name') as HTMLInputElement).value,
          expirationMonth: expMonth,
          expirationYear: (document.querySelector('#expYear') as HTMLInputElement).value,
        };

        SharedUtils.debugLog('Tokenizing card data...');
        microform.createToken(createTokenOptions, (err: any, transientToken: string) => {
          if (err) {
            SharedUtils.debugLog(err);
            if (onPaymentEnd) onPaymentEnd();
            //TODO: This message should probably come from the config, but current focus is just to raise the error
            // so it goes to the error page instead of silently failing when the processing backdrop closes
            if (onPaymentError) onPaymentError("card", "An error occurred setting up your payment. Please try again later.");
          } else {
            SharedUtils.debugLog('Tokenization successful');
            onPaymentRequestable('card', transientToken, createTokenOptions.name);
          }
        });
      });
    };
  }

  const childrenUI = React.useMemo(
    () =>
      (!scriptLoaded || !methods)
        ? onLoading()
        : children(methods, amount),
    [scriptLoaded, methods, amount, onLoading]
  );

  return (
    <>
      {childrenUI}
      <div id="errorsOutput"></div>
      <iframe
        title="cybersource-device-iframe"
        id="cybersource-device-iframe"
        name="cybersource-device-iframe"
        height="1"
        width="1"
        style={{ display: 'none' }}
      ></iframe>
      <form id="cybersource-device-form" method="POST" target="cybersource-device-iframe">
        <input id="cybersource-device-jwt" type="hidden" name="JWT" />
      </form>
    </>
  );
}
