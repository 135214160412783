import React, { useState, useEffect } from "react";
import {
    PayPalScriptProvider,
    PayPalButtons,
    ReactPayPalScriptOptions,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import PaymentAmountMessage from './PaymentAmountMessage';
import TermsAndConditionsMessage from './TermsAndConditionsMessage';
import SecurePayment from './SecurePayment';
import PaymentMethodAccordionPanel from "./PaymentMethodAccordionPanel";
import { MyApp } from "../../../context/AppContext";
import { SharedUtils } from "../../../utils/SharedUtils";
import { CompletePaymentRQ } from "../../../api/rq/CompletePaymentRQ";
import { FailedReceiptDto } from "../body/FailedReceipt";
import { SuccessReceiptDto } from "../body/SuccessReceipt";
import { PayPalNamespace, FUNDING_SOURCE } from "@paypal/paypal-js";
import { AccordionConfigDto } from "../../../api/dto/AccordionConfigDto";

export type PayPalDirectConfig = {
    clientId: string;
    enableFunding?: string[];
    disableFunding?: string[];
    currency: string;
    locale: string;
    intent: string;
    components?: string[];
    environment?: "production" | "sandbox";
    payLaterEnabled: boolean;
};

export type PaymentMethodPayPalDirectProps = {
    domain: string;
    paymentAmount: number;
    currencySymbol: string;
    aboutToPayMessageEnabled: boolean;
    setFailedReceipt: React.Dispatch<React.SetStateAction<FailedReceiptDto | undefined>> | undefined;
    setSuccessReceipt: React.Dispatch<React.SetStateAction<SuccessReceiptDto | undefined>> | undefined;
    accordionExpanded: string | false;
    handleAccordionChange: (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    paypalConfig: PayPalDirectConfig;
    accordionConfig: AccordionConfigDto;
};

function ButtonWrapper({
    createOrder,
    onApprove,
    onError,
    fundingSource
}: {
    createOrder: (shouldVault: boolean) => Promise<string>;
    onApprove: (data: any, shouldVault: boolean) => Promise<void>;
    onError: (err: any) => void;
    fundingSource: FUNDING_SOURCE;
}) {
    const [{ isPending }] = usePayPalScriptReducer();

    return (
        <>
            {isPending ? <div>Loading PayPal Buttons...</div> : null}

            <PayPalButtons
                fundingSource={fundingSource}
                style={{ layout: "vertical" }}
                createOrder={() => createOrder(fundingSource === "paypal")}
                onApprove={(data) => onApprove(data, fundingSource === "paypal")}
                onError={onError}
            />
        </>
    );
}

function PayPalContent(props: PaymentMethodPayPalDirectProps) {
    const {
        domain,
        paymentAmount,
        currencySymbol,
        aboutToPayMessageEnabled,
        setFailedReceipt,
        setSuccessReceipt,
        accordionExpanded,
        handleAccordionChange,
        paypalConfig,
        accordionConfig
    } = props;

    const [paypalError, setPaypalError] = useState<string | null>(null);
    const [availableFundingSources, setAvailableFundingSources] = useState<FUNDING_SOURCE[]>([]);
    const [{ isResolved }] = usePayPalScriptReducer();

    useEffect(() => {
        if (isResolved && window.paypal) {
            const paypal = window.paypal as PayPalNamespace;
            if (paypal.getFundingSources && paypal.Buttons) {
                const eligibleSources: FUNDING_SOURCE[] = [];
                paypal.getFundingSources().forEach((fundingSource) => {
                    const button = (paypal.Buttons as any)({
                        fundingSource: fundingSource
                    });
                    if (typeof button.isEligible === 'function' && button.isEligible()) {
                        eligibleSources.push(fundingSource as FUNDING_SOURCE);
                    }
                });
                setAvailableFundingSources(eligibleSources);
            }
        }
    }, [isResolved]);

    const createOrder = async (shouldVault: boolean): Promise<string> => {
        try {
            const response = await MyApp.paypalCreateOrder(shouldVault);

            if (!response?.orderId) {
                throw new Error(`Unable to get OrderId`);
            }

            return response.orderId;
        } catch (error: any) {
            SharedUtils.debugLog("Error in createOrder:", error);
            throw error;
        }
    };

    const processPaymentResult = (result: any) => {
        // Error Messages
        const unknownError = "UNKNOWN"
        const invalidDataError = "INVALID_DATA";
        const havePaidError = "You have already paid your balance";
        const genericError = "There was an error during the processing of the payment"

        if (result == null) {
            MyApp.setError(genericError)
            return
        };

        // Error codes
        const paymentInternalRefErrorCode = "IR001"
        const paymentTimeoutErrorCode = "PP001"
        const paymentProcessingErrorCode = "PP002"

        // check for specific error codes and re-wash reason if required
        if (result.reasonCode) {
            if (result.reasonCode === paymentInternalRefErrorCode ||
                result.reasonCode === paymentProcessingErrorCode ||
                result.reasonCode === paymentTimeoutErrorCode ||
                result.reasonCode === unknownError) {
                result.reason = "There was an error processing your payment, please try again later."
            }
        }

        // Check if it was a success or failed payment
        if (result.reason) {
            if (result.reason.includes(havePaidError)) {
                // If the fail reason is that of an "already paid" result,
                // show this on the error page rather then in the FailedReceipt
                MyApp.setError(result.reason);
            } else {
                // Result destined for the failed receipt page
                if (result.reason.includes(invalidDataError)) {
                    result.reason = "It looks like your request timed out. Please try again later."
                }
                if (setFailedReceipt) {
                    setFailedReceipt({
                        reference: result.externalReference,
                        account: result.consumerAccount ?? "",
                        maxAttemptsReached: result.maxAttemptsReached ?? false,
                        reason: result.reason,
                        errorCode: result.reasonCode ?? "",
                        maxAttemptsReason: result.maxAttemptsReason,
                    });
                }
            }
        } else {
            if (setSuccessReceipt) {
                setSuccessReceipt({
                    reference: result.externalReference,
                    account: result.consumerAccount,
                    amount: result.chargedAmount,
                    cardRegistered: result.cardSaved
                });
            }
        }
    }

    const onApprove = async (data: any, shouldVault: boolean) => {
        try {
            const completePaymentRequest: CompletePaymentRQ = {
                amount: paymentAmount,
                nonce: JSON.stringify({ OrderId: data.orderID }),
                registerToken: shouldVault,
                deviceData: "",
            };

            const gpgResponse = await MyApp.completePayment(completePaymentRequest);
            SharedUtils.debugLog("PayPal capture order: ", gpgResponse);
            processPaymentResult(gpgResponse);

        } catch (error: any) {
            processPaymentResult(null);
        }
    };

    const onError = (err: any) => {
        SharedUtils.debugLog(`PayPal error:`, err);
        setPaypalError("An error occurred with PayPal. Please try again.");
        processPaymentResult(null);
    };

    if (paypalError) {
        processPaymentResult(null);
    }

    const getPayPalAccordionConfig = (type: 'standard' | 'paylater') => {
        return accordionConfig.PayPalDirect.find(item => item.type.toLocaleLowerCase() === type.toLocaleLowerCase()) || { title: type === 'standard' ? 'PayPal' : 'PayPal Pay Later', icon: 'paypal-logo-stacked.png' };
    };

    const standardPayPalConfig = getPayPalAccordionConfig('standard');
    const payLaterConfig = getPayPalAccordionConfig('paylater');

    return (
        <>
            {availableFundingSources.includes("paypal") && (
                <PaymentMethodAccordionPanel
                    accordionExpanded={accordionExpanded}
                    handleAccordionChange={handleAccordionChange}
                    panelName="paypalPanel"
                    panelText={standardPayPalConfig.title || "PayPal"}
                    panelIcon={standardPayPalConfig.icon || "paypal-logo-stacked.png"}
                    domain={domain}
                >
                    <div className="payment-section-div">
                        {aboutToPayMessageEnabled && (
                            <PaymentAmountMessage
                                currencySymbol={currencySymbol}
                                amount={paymentAmount}
                            />
                        )}
                        <TermsAndConditionsMessage />
                        <ButtonWrapper
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={onError}
                            fundingSource={"paypal"}
                        />
                        <SecurePayment domain={domain} name="PayPal" />
                    </div>
                </PaymentMethodAccordionPanel>
            )}

            {availableFundingSources.includes("paylater") &&
                paymentAmount >= 30 &&
                paypalConfig.payLaterEnabled &&
                (
                    <PaymentMethodAccordionPanel
                        accordionExpanded={accordionExpanded}
                        handleAccordionChange={handleAccordionChange}
                        panelName="paypalPayLaterPanel"
                        panelText={payLaterConfig.title || "PayPal Pay Later"}
                        panelIcon={payLaterConfig.icon || "paypal-logo-stacked.png"}
                        domain={domain}
                    >
                        <div className="payment-section-div">
                            {aboutToPayMessageEnabled && (
                                <PaymentAmountMessage
                                    currencySymbol={currencySymbol}
                                    amount={paymentAmount}
                                />
                            )}
                            <TermsAndConditionsMessage />
                            <ButtonWrapper
                                createOrder={createOrder}
                                onApprove={onApprove}
                                onError={onError}
                                fundingSource={"paylater"}
                            />
                            <SecurePayment domain={domain} name="PayPal Pay Later" />
                        </div>
                    </PaymentMethodAccordionPanel>
                )}
        </>
    );
}

export default function PaymentPayPalDirect(props: PaymentMethodPayPalDirectProps) {
    const paypalOptions: ReactPayPalScriptOptions = {
        environment: props.paypalConfig.environment,
        clientId: props.paypalConfig.clientId,
        currency: props.paypalConfig.currency,
        locale: props.paypalConfig.locale,
        intent: props.paypalConfig.intent,
        disableFunding: props.paypalConfig.disableFunding,
        enableFunding: props.paypalConfig.enableFunding,
        components: props.paypalConfig.components
    };

    return (
        <PayPalScriptProvider options={paypalOptions}>
            <PayPalContent {...props} />
        </PayPalScriptProvider>
    );
}