
import React from "react";
import { PaymentDto } from "../../../../api/dto/PaymentDto";
import { FailedReceiptDto } from "../../body/FailedReceipt";
import { SuccessReceiptDto } from "../../body/SuccessReceipt";
import PaymentMethodAccordionPanel from "../PaymentMethodAccordionPanel";
import IllionCyberSource, { GooglePayOptions } from "./IllionCyberSource";
import { Skeleton, Typography } from "@mui/material";
import PaymentMethodApplePay from "../cybersource/PaymentMethodApplePay";
import PaymentMethodCreditCard, { CardTypes, FieldStatusCyberSource, defaultFieldStatusesCyberSource } from "../PaymentMethodCreditCard";
import ProcessingBackDrop, { ProcessingBackDropProps, defaultProcessingState } from "../ProcessingBackDrop";
import { onCardTypeChangeHandler, onPaymentRequestableHandler, onValidityChangeHandler } from "./PaymentCyberSourceHandlers";
import { MyApp } from "../../../../context/AppContext";
import { PaymentConfigDto } from "../../../../api/dto/PaymentConfigDto";
import { AccordionConfigDto } from "../../../../api/dto/AccordionConfigDto";
import PaymentMethodGooglePay from "../PaymentMethodGooglePay";
import PaymentPayPalDirect, { PayPalDirectConfig } from "../PaymentMethodPayPalDirect";
import { SharedUtils } from "../../../../utils/SharedUtils";

// +--------------------------------------------------+
//              PaymentCyberSource Props
// +--------------------------------------------------+

export type PaymentCyberSourceProps = {
  domain: string;
  paymentAmount: number;
  currencySymbol: string,
  paymentData: PaymentDto;
  paymentConfig: PaymentConfigDto;
  accordionConfig: AccordionConfigDto;
  aboutToPayMessageEnabled: boolean,
  setFailedReceipt: React.Dispatch<React.SetStateAction<FailedReceiptDto | undefined>>
  setSuccessReceipt: React.Dispatch<React.SetStateAction<SuccessReceiptDto | undefined>>
};

// +--------------------------------------------------+
//             PaymentCyberSource Component
// +--------------------------------------------------+

export default function PaymentCyberSource(props: PaymentCyberSourceProps) {
  //const { domain, paymentAmount, paymentData, setFailedReceipt, setSuccessReceipt } = props;
  const {
    domain,
    paymentData,
    paymentAmount,
    currencySymbol,
    paymentConfig,
    accordionConfig,
    aboutToPayMessageEnabled,
    setFailedReceipt,
    setSuccessReceipt
  } = props;

  const { paymentProcessingConfig, labels } = MyApp.state;

  const paypalConfigDefaults: PayPalDirectConfig = {
    clientId: "",
    enableFunding: ["paylater"],
    disableFunding: ["card"],
    currency: paymentData.site.currencyCode,
    locale: "en_" + paymentData.site.culture,
    intent: "capture",
    components: ["buttons", "funding-eligibility", "messages"],
    payLaterEnabled: paymentConfig.enabledPaymentMethods.PayPalPayLaterEnabled,
  };

  let parsedPaypalConfig = SharedUtils.parseConfig<PayPalDirectConfig>("paypal", paymentData);

  const paypalConfig: PayPalDirectConfig = {
    ...paypalConfigDefaults,
    ...parsedPaypalConfig
  };

  // Tracks state of which accordion item is expanded and closes the rest
  const [accordionExpanded, setAccordionExpanded] = React.useState<string | false>(false);
  // Accordion Handler
  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setAccordionExpanded(isExpanded ? panel : false);
  };

  // Used by the Credit Card section
  const [cardType, setCardType] = React.useState<CardTypes>(CardTypes.none);
  const [fieldStatus, setFieldStatus] = React.useState<FieldStatusCyberSource>(defaultFieldStatusesCyberSource);

  // Processing backdrop with spinner and message that the payment is being processed
  const [processing, setProcessing] = React.useState<ProcessingBackDropProps>(defaultProcessingState);
  const deviceDataRef = React.useRef<string>();

  // Default settings
  const googlePayDefaults: GooglePayOptions = {
    totalPriceStatus: "FINAL",
    version: 2,
    buttonOptions: { buttonSizeMode: 'fill', buttonType: "pay" },
    allowedCardNetworks: ["AMEX", "MASTERCARD", "VISA"]
  };

  var googlePayOptions = SharedUtils.parseConfig<GooglePayOptions>("googlePay", paymentData);

  return (
    <IllionCyberSource
      amount={{ currency: paymentData.site.currencyCode, total: paymentAmount }}
      environment={paymentData.site.siteConfig["environment"] as any}
      googlePay={{ ...googlePayDefaults, ...googlePayOptions }}
      enabledPaymentMethods={paymentConfig.enabledPaymentMethods}
      onCardTypeChange={(event) => { onCardTypeChangeHandler(event, setCardType, setFieldStatus) }}
      onValidityChangeHandler={(event) => { onValidityChangeHandler(event, setFieldStatus) }}
      onError={(method, reason) => SharedUtils.debugLog(method, reason)}
      onPaymentError={(method, reason) => { MyApp.setError(reason); }}
      onLoading={() => <Skeleton variant="rectangular" height={95} />}
      onPaymentStart={() => {
        setProcessing({ isProcessing: true, content: paymentProcessingConfig.processingMessage, fadeInDur: paymentProcessingConfig.messageFadeInDuration })
      }}
      onPaymentEnd={() => {
        setProcessing({ isProcessing: false, content: "", fadeInDur: paymentProcessingConfig.messageFadeInDuration })
      }}
      onPaymentRequestable={async (method: string, transientToken: string, cardHolderName: string) => {
        await onPaymentRequestableHandler(method, transientToken, paymentAmount, paymentProcessingConfig, deviceDataRef,
          setProcessing, setFailedReceipt, setSuccessReceipt, cardHolderName)
      }}
    >
      {(methods, amount) => {
        return (
          <div className="Payment-container">
            {ProcessingBackDrop(processing)}

            <Typography mb={2} variant="h2" className="header">
              How would you like to pay?
            </Typography>
            {/* 
          -----------------------------
                  Credit Card 
          -----------------------------
          */}
            {paymentConfig.enabledPaymentMethods.CreditCardEnabled && methods.card &&
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="cardPanel"
                panelText={accordionConfig!.Card?.title ?? 'Credit Card'}
                panelIcon="card-pay.png"
                domain={domain}
              >
                <PaymentMethodCreditCard
                  aboutToPayMessageEnabled={aboutToPayMessageEnabled}
                  currencySymbol={currencySymbol}
                  fieldStatus={fieldStatus} cardType={cardType}
                  amountTotal={amount.total}
                  domain={domain}
                  creditCardRef={methods.card}
                  displaySurcharge={false}
                  displayCardTypes={paymentConfig?.displayCardTypes}
                  labels={labels}
                />
              </PaymentMethodAccordionPanel>
            }

            {/* 
          -----------------------------
                  PayPal 
          -----------------------------
          */}
            {paymentConfig.enabledPaymentMethods.PayPalEnabled &&
              paypalConfig.clientId &&
              <PaymentPayPalDirect
                domain={domain}
                paymentAmount={amount.total}
                currencySymbol={currencySymbol}
                aboutToPayMessageEnabled={aboutToPayMessageEnabled}
                setFailedReceipt={setFailedReceipt}
                setSuccessReceipt={setSuccessReceipt}
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                accordionConfig={accordionConfig}
                paypalConfig={{
                  ...paypalConfig,
                  payLaterEnabled: paymentConfig.enabledPaymentMethods.PayPalPayLaterEnabled
                }}
              />
            }

            {/* 
          -----------------------------
                    Apple Pay 
          -----------------------------
          */}
            {paymentConfig.enabledPaymentMethods.ApplePayEnabled &&
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="applepayPanel"
                panelText="Apple Pay"
                panelIcon="apple-pay-logo.svg"
                domain={domain}
              >
                <PaymentMethodApplePay aboutToPayMessageEnabled={aboutToPayMessageEnabled} currencySymbol={currencySymbol} isAvailable={checkApplePaySupport()} amountTotal={amount.total} domain={domain} applePayRef={methods.applePay} paymentData={paymentData} setFailedReceipt={setFailedReceipt} setSuccessReceipt={setSuccessReceipt} />
              </PaymentMethodAccordionPanel>
            }
            {/* 
            -----------------------------
                     Google Pay 
            -----------------------------
            */}
            {(paymentConfig.enabledPaymentMethods.GooglePayEnabled && methods.googlePay) && (
              <PaymentMethodAccordionPanel
                accordionExpanded={accordionExpanded}
                handleAccordionChange={handleAccordionChange}
                panelName="gpayPanel"
                panelText="Google Pay"
                panelIcon="google-pay-logo.png"
                domain={domain}
              >
                <PaymentMethodGooglePay aboutToPayMessageEnabled={aboutToPayMessageEnabled} currencySymbol={currencySymbol} amountTotal={amount.total} domain={domain} googlePayRef={methods.googlePay} />
              </PaymentMethodAccordionPanel>
            )}
          </div>
        );
      }}
    </IllionCyberSource>
  )
}


interface ApplePayWindow extends Window {
  ApplePaySession?: any;
}

declare let window: ApplePayWindow;

declare let ApplePaySession: any;

function checkApplePaySupport(): boolean {
  if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
    return true
  }
  return false
}
