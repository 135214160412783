import { createClient, IApi } from "@etsoo/restclient";
import React from "react";
import { PaymentDto, PaymentResponse } from "../api/dto/PaymentDto";
import { AppConstants } from "./AppConstants";
import { AppState } from "./AppState";
import { AppStateAction, AppStateDispatch, IAppContext } from "./IAppContext";
import { VerifyPasscodeDto, VerifyRequestDto, VerifyResponseDto } from "../api/dto/VerifyRp";
import { CompletePaymentResponseDto } from "../api/dto/CompletePaymentResponseDto";
import { ApplePayGetPaymentSessionResponseDto } from "../api/dto/ApplePayGetPaymentSessionResponseDto";
import { CompleteDigitalWalletPaymentResponseDto } from "../api/dto/CompleteDigitalWalletPaymentResponseDto";
import { CardOptOutResponseDto } from "../api/dto/CardOptOutResponseDto";
import { CompletePaymentRQ } from "../api/rq/CompletePaymentRQ";
import { ThreeDSecureEnrolmentRequest, ThreeDSecureEnrolmentResponse, ThreeDSecureSetupResponse, ThreeDSecureSetupRQ } from "../api/dto/ThreeDSecureDto";
import { PayPalCreateOrderResponseDto } from "../api/dto/PayPalCreateOrderResponseDto";

export class AppContext implements IAppContext {
  /**
   * App Context
   */
  context: React.Context<AppState>;

  /**
   * App state
   */
  state: AppState;

  /**
   * App state dispatch
   */
  dispatch: React.Dispatch<AppStateDispatch>;

  /**
   * App context provider
   */
  provider: React.FunctionComponent<React.PropsWithChildren>;

  /**
   * localhost for http://localhost:4200/landing?query=1#2
   */
  readonly hostname: string;

  /**
   * Local domain path like '/domains/localhost'
   */
  readonly domainPath: string;

  /**
   * Payment token
   */
  readonly token: string;

  /**
   * Verify RP token
   */
  verifyRpToken?: string;

  /**
   * API client
   */
  readonly api: IApi;

  cardPin: string | undefined;

  internalReference: string | undefined;

  constructor(hostname?: string | null, token?: string | null) {
    // Hostname
    this.hostname = globalThis.location.hostname === 'localhost' ? 'default' : globalThis.location.hostname;

    this.domainPath = `/domains/${process.env.REACT_APP_RESOURCE_DOMAIN ?? this.hostname}`;

    // Token, /p/123456
    this.token = token ?? globalThis.location.pathname.split("/")[2];

    // API
    this.api = createClient();
    this.api.baseUrl = process.env.REACT_APP_API_ENDPOINT;

    // Default state
    const defaultState = {} as AppState;
    if (!this.token) defaultState.error = AppConstants.NoTokenError;

    // Context for
    const context = React.createContext(defaultState);
    this.context = context;

    // Provider to integrate with the UI
    const Provider: React.FunctionComponent<React.PropsWithChildren> = ({
      children,
    }) => {
      // State reducer
      // https://beta.reactjs.org/reference/react/useReducer
      const [state, dispatch] = React.useReducer(
        // Specifies how the state gets updated
        (state: AppState, action: AppStateDispatch) => {
          return this.stateReducer(state, action);
        },
        defaultState
      );

      // Keep updating
      this.dispatch = dispatch;

      return <context.Provider value={state}>{children}</context.Provider>;
    };

    this.provider = Provider;
    this.state = defaultState;

    // We can make sure the provider is rendered
    this.dispatch = null!;

    // Update icons
    this.updateIcons();
  }

  /**
   *
   * @param state Current state
   * @param da Dispatch action
   * @returns New state
   */
  private stateReducer(state: AppState, da: AppStateDispatch) {
    // Destruct
    const { action, data } = da;

    switch (action) {
      case AppStateAction.ERROR:
        return typeof data === "string"
          ? { ...state, error: data }
          : { ...state, ...data };
      case AppStateAction.READY:
        return { ...state, error: undefined, ready: true };
      default:
        // When you only copy the update data but not re-renderer
        Object.assign(state, data);
    }

    // Return current state, no rerender will happen
    return state;
  }

  // Helper function to update or create meta tags
  private updateMetaTag(name: string, content: string) {
    let metaTag = document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement;

    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.name = name;
      document.head.appendChild(metaTag);
    }

    metaTag.setAttribute('content', content);
  }

  /**
   * Load static resource of the domain
   * @returns Result
   */
  loadStaticResource() {
    // Chrome has moments when it will continue to reference for a while a local cached 'resources.json' result even though the file
    // itself has been updated and the cloudfront cache refreshed. Firefox/Edge don't seem to have this problem. Adding an unused 
    // 'nocache' url param (epoc time) to the url call to help force chrome to not use a cached version. The 'resources.json' file can
    // be updated outside of the client UI bundle and webpay links are not generated on a high frequency, so always loading a
    // fresh 'resources.json' file seems acceptable in this scenario, I couldn't think of a better option at the time TBH.
    var rnd = (new Date()).getTime();

    return this.api.get<AppState>(
      `${this.domainPath}/resource.json?nocache=${rnd}`,
      undefined,
      { local: true }
    );
  }

  /**
   * Query payment token
   */
  queryToken(verifyRpToken?: string) {
    this.verifyRpToken = verifyRpToken;
    return this.api.post<PaymentResponse>(`Payment/QueryToken`, {
      domain: this.hostname,
      token: this.token,
      verifyRpToken: this.verifyRpToken,
    });
  }

  /**
   * Get card token from apple
   */
  applePayGetPaymentSession(validationURL: string) {
    return this.api.post<ApplePayGetPaymentSessionResponseDto>(`Payment/ApplePayGetPaymentSession`, {
      UrlToken: this.token,
      ValidationUrl: validationURL,
    });
  }

  /**
   * Cybersource Digital Wallet Payment Request - Apple/Google Pay
   */
  CybersourceDigitalWalletPayment(cardTokenJWT: string, paymentChannel: string) {
    return this.api.post<CompleteDigitalWalletPaymentResponseDto>(`Payment/CybersourceDigitalWalletPayment`, {
      UrlToken: this.token,
      CardTokenJWT: cardTokenJWT,
      PaymentChannel: paymentChannel
    });
  }

  /**
  * PayPalCreateOrder
  * @param rq Request data
  */
  paypalCreateOrder(registerToken: boolean) {
    const result = this.api.post<PayPalCreateOrderResponseDto>(`Payment/PayPalOrderRequest`, {
      UrlToken: this.token,
      RegisterToken: registerToken
    });

    return result;
  }

  /**
   * Trigger Card OptOut
   */
  cardOptOut() {
    var cardPin: string = "";

    if (this.cardPin) {
      cardPin = this.cardPin;
    }

    return this.api.post<CardOptOutResponseDto>(`Payment/CardOptOut`, {
      urlToken: this.token,
      CardPin: cardPin
    });
  }

  /**
   * Complete payment
   * @param rq Request data
   */
  completePayment(rq: CompletePaymentRQ) {
    return this.api.post<CompletePaymentResponseDto>(`Payment/CompletePayment`, {
      urlToken: this.token,
      verifyRpToken: this.verifyRpToken,
      ...rq
    });
  }

  /**
   * Sends the passcode for each VRP challenge to the server for verification.
   * @param passcodes The passcodes to the challenge(s).
   * @returns the verification response.
   */
  verify(passcodes: VerifyPasscodeDto[]) {
    let request: VerifyRequestDto = {
      urlToken: this.token,
      postCode: passcodes.find(passcode => passcode.challenge === 'POSTCODE')?.value,
      dateOfBirth: passcodes.find(passcode => passcode.challenge === 'DOB')?.value,
    };
    return this.api.post<VerifyResponseDto>('Payment/VerifyRightParty', request);
  }

  /**
   * Set Link href
   * @param rel Link rel
   * @param href href property
   */
  setLinkHref(rel: string, href: string) {
    let link = document.querySelector<HTMLLinkElement>(`link[rel="${rel}"]`);
    if (link == null) {
      link = document.createElement("link");
      link.rel = rel;
      document.head.append(link);
    }
    link.href = href;
  }

  /**
   * Get Cybersource Server Side Context
   * @param 
   */
  createServerSideContext() {
    return this.api.post<string>(`Payment/CreateServerSideContext`, {
      urlToken: this.token
    });
  }

  /**
 * Get Cybersource Server Side Context
 * @param transientToken Token from the Microform once fields are submitted
 */
  setup3DSPayment(transientToken: string) {
    let request: ThreeDSecureSetupRQ = {
      urlToken: this.token,
      token: transientToken,
    };
    return this.api.post<ThreeDSecureSetupResponse>('Payment/Setup3dsPayment', request);
  }

  /**
* Get Cybersource Server Side Context
* @param transientToken Token from the Microform once fields are submitted
*/
  threeDSecureEnrolment(referenceId: string, transientTokenJwt: string, cardHolderName: string) {
    const request: ThreeDSecureEnrolmentRequest = {
      webpayToken: this.token,
      referenceId: referenceId,
      transientTokenJwt: transientTokenJwt,
      returnUrl: '',
      cardHolderName: cardHolderName,
      internalRef: this.internalReference ?? '',
    };
    return this.api.post<ThreeDSecureEnrolmentResponse>('Payment/CyberSourceEnrolment', request);
  }

  /**
   * Set application is ready
   */
  setReady() {
    this.dispatch({ action: AppStateAction.READY });
  }

  /**
   * Set error message
   * @param message Error message
   */
  setError(message?: string) {
    this.dispatch({ action: AppStateAction.ERROR, data: message });
  }

  /**
   * Update icons for different scenarios
   */
  updateIcons() {
    this.setLinkHref("icon", `${this.domainPath}/favicons/favicon.ico`);
    this.setLinkHref(
      "apple-touch-icon",
      `${this.domainPath}/favicons/apple-touch-icon.png`
    );
  }

  /**
   * Update resource
   * @param res Resource
   * @param payment Payment data
   */
  updateResource(res: AppState) {
    document.title = res.appName;

    // Update meta tags for link preview
    if (res.appDescription && res.appImagePreview && res.appName) {
      this.updateMetaTag('description', res.appDescription || '');
      this.updateMetaTag('image', res.appImagePreview || '');

      // Update Open Graph tags
      this.updateMetaTag('og:title', res.appName);
      this.updateMetaTag('og:description', res.appDescription || '');
      this.updateMetaTag('og:image', res.appImagePreview || '');
    }

    this.dispatch({ action: AppStateAction.RESOURCE, data: res });
  }

  updatePaymentData(payment: PaymentDto) {
    this.dispatch({ action: AppStateAction.PAYMENTDATA, data: { payment } });
  }

  setCardPin(cardPin: string) {
    this.cardPin = cardPin;
  }

  setInternalRef(internalRef: string): void {
    this.internalReference = internalRef;
  }

  getInternalRef(): string {
    return this.internalReference ?? '';
  }
}

/**
 * My app context
 * For testing, initialize with a static hostname
 */
export let MyApp: IAppContext;

export default function CreateInstance() {
  MyApp = new AppContext();
  return MyApp;
}